var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-center px-3"},[_c('div',{staticClass:"mw--100"},[_c('div',{staticClass:"flex-center flex-column text-center"},[_c('div',{staticClass:"pb-3"},[_vm._v(_vm._s(_vm.$t("groups.register.welcome")))]),_c('div',{staticClass:"pb-5 text-pre"},[_vm._v(_vm._s(_vm.$t("groups.register.remind")))]),_c('span',{staticClass:"pb-1 text-pre text-danger"},[_vm._v(" "+_vm._s(_vm.$t("groups.member_list.qr_code.remind_qr_3"))+" ")])]),_c('ValidationObserver',{staticClass:"form d-flex flex-column px-3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"register-form py-3"},[_c('div',{staticClass:"mail-address pt-3"},[_c('ValidationProvider',{attrs:{"rules":{
              required: true,
              email: true,
              max: 255,
              no_script_tag: true,
            },"name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("common.form.mail_address"))+" "),_c('span',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t("common.form.require"))+" ")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control h--10 px-4 py-2 fs-12 text-dark rounded-2",class:{ 'border-danger': errors[0] | _vm.isError },attrs:{"placeholder":_vm.$t('common.form.mail_address'),"name":"email","type":"text"},domProps:{"value":(_vm.email)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.email=$event.target.value},_vm.handleInput]}}),(_vm.success && !errors[0])?_c('span',{staticClass:"text-success slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(_vm.messageSuccess)+" ")]):_vm._e(),(errors[0])?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}],null,true)}),(_vm.isError)?_c('span',{staticClass:"text-danger slide-down-40-percent d-block z-n1"},[_vm._v(" "+_vm._s(_vm.messageEr)+" ")]):_vm._e()],1)]),_c('div',{staticClass:"flex-center pt-5"},[_c('button',{staticClass:"btn btn-primary text-light fs-15 rounded-5 w-100 mw--60 py-2",on:{"click":function($event){return handleSubmit(_vm.submitRegister)}}},[_vm._v(" "+_vm._s(_vm.$t("common.btn.btn_send"))+" ")])]),_c('div',{staticClass:"flex-center pt-5"},[_c('button',{staticClass:"btn btn-success fs-15 rounded-5 w-100 mw--60 py-2",on:{"click":_vm.goToLogin}},[_vm._v(" "+_vm._s(_vm.$t("before_login.header_login"))+" ")])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }